<template>
   <div class="RightView">
    <div class="top">
        <div class="card">
            <div class="boxHeader">
                <span class="title" @click="fetchData">非油实时销售统计</span>
            </div>
            <div class="notOilSales"> 
                <div>
                    <div class="title" @click="changeNum">
                    非油销售金额
                    </div>
                    <eNumber :num="notOilSales.pay_money" :cstyle="notOilSales_style" ></eNumber>
                </div>
                <div>
                    <div class="title">
                    非油销售数量
                    </div>
                    <eNumber :num="notOilSales.count" :cstyle="notOilSales_style"></eNumber>
                </div>
            </div>
        </div>
    </div>
    <div class="middle">
        <div class="card">
            <div class="boxHeader">
                <div class="title">销售排行
                    <span class="bold">{{ rankType == 1 ? '[油品]':'[非油]' }}</span>
                </div>
                <div>
                    <xxyButton v-model="rankType" :active="1">油品</xxyButton>
                    <xxyButton v-model="rankType" :active="2">非油</xxyButton>
                </div>
            </div>
            <div class="box">
                <xxySalesRankingBoard ref="xxySalesRankingBoard" :data="rankData" :unit="rankType == 1? '升' : '笔'" @changeRank="changeRank" :pause="pause"></xxySalesRankingBoard>
            </div>
        </div>
        <div class="card">
            <div class="boxHeader">
                <span class="title">各站核销情况</span>
                <div>
                    <xxyButton v-model="couponRankType" :active="1">抽奖券</xxyButton>
                    <xxyButton v-model="couponRankType" :active="2">洗车券</xxyButton>
                </div>
            </div>
            <div>
                <xxyCouponRankingBoard ref="xxyCouponRankingBoard" :data="couponRankData" @changeView="changeCouponRank" :pause="pause"></xxyCouponRankingBoard>
            </div>
        </div>
    </div>
    <div class="bottom">
        <div class="card">
            <div class="boxHeader">
                <span class="title">自定义排行</span>
            </div>
            <div class="rank">
                <img :src="costomRankData.url" alt="" v-if="costomRankData">
                <div class="tip" v-else>未设置</div>
            </div>
        </div>
        <div class="card">
            <div class="boxHeader">
                <span class="title">待处理事件</span>
            </div>
            <div>
                <xxyEvent :data="eventData" :pause="pause"></xxyEvent>
            </div>
        </div>
    </div>
   </div>
</template>

<script>
import eNumber from '@/components/e-number1.vue'
import xxySalesRankingBoard from '@/components/xxy-sales-ranking-board.vue'
import xxyCouponRankingBoard from '@/components/xxy-coupon-ranking-board.vue'
import {getRight} from '@/utils/api'
import xxyButton from '@/components/xxy-button.vue'
import xxyEvent from '@/components/xxy-event.vue'

export default {
    name: 'RightView',
    components: {
        eNumber,
        xxySalesRankingBoard,
        xxyCouponRankingBoard,
        xxyButton,
        xxyEvent
    },
    props: {
        pause: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            notOilSales_style:{
                fontSize: '24px',
                padding: '8px',
                margin: '2px',
                fontWeight: 'bold',
            },
            notOilSales: {
                count: '0',
                pay_money: '0'
            },
            rank: {
                oil_rank: [],
                not_oil_rank: []
            },
            rankType: 1,
            rankData: [],
            couponRankType: 1,
            luckyRank: [],
            carWashRank: [],
            couponRankData: [],
            eventData: [],
            costomRankData: {},
        }
    },
    watch:{
        rankType(){
            this.gRankData()
        },
        couponRankType(){
            this.gCouponRankData();
        },
    },
    created() {
    },
    mounted() {
    },
    methods:{
        changeCouponRank(){
            this.couponRankType = this.couponRankType == 1? 2: 1;
            this.$refs.xxyCouponRankingBoard.begin()
        },
        changeRank(){
            this.rankType = this.rankType == 1? 2: 1;
            this.$refs.xxySalesRankingBoard.begin()
        },
        intervalChange(){
        },
        changeNum(){
            console.log('changeNum')
            if( this.notOilSales.pay_money == 1232213.45){
                this.notOilSales.pay_money = 5461134.58
            }else{
                this.notOilSales.pay_money = 1232213.45
            }
        },
        gRankData(){
            this.rankData = this.rankType == 1 ? this.rank.oil_rank : this.rank.not_oil_rank;
        },
        gCouponRankData(){
            this.couponRankData = this.couponRankType == 1 ? this.luckyRank : this.carWashRank;
            // if(this.couponRankType == 1){
            //     this.couponRankData = [
            //         {
            //         "use_total": 505,
            //         "use_total_money": 7131.89990234375,
            //         "use_station_no": "S22052710383088",
            //         "station_name": "永福加油站"
            //         },
            //         {
            //         "use_total": 464,
            //         "use_total_money": 7222.89990234375,
            //         "use_station_no": "S22053109400564",
            //         "station_name": "新时代加油站"
            //         },
            //         {
            //         "use_total": 312,
            //         "use_total_money": 5194.89990234375,
            //         "use_station_no": "S22061310321832",
            //         "station_name": "汇丰加油站"
            //         },
            //         {
            //         "use_total": 272,
            //         "use_total_money": 3685.0,
            //         "use_station_no": "S22050515470130",
            //         "station_name": "兴珑加油站"
            //         },
            //         {
            //         "use_total": 225,
            //         "use_total_money": 3364.89990234375,
            //         "use_station_no": "S22062009573666",
            //         "station_name": "兴华加油站"
            //         },
            //         {
            //         "use_total": 215,
            //         "use_total_money": 2708.0,
            //         "use_station_no": "S22061616013992",
            //         "station_name": "望牛墩加油站"
            //         },
            //         {
            //         "use_total": 192,
            //         "use_total_money": 1983.0,
            //         "use_station_no": "S22082314225228",
            //         "station_name": "匠心加油站"
            //         },
            //         {
            //         "use_total": 172,
            //         "use_total_money": 2354.0,
            //         "use_station_no": "S22080417382161",
            //         "station_name": "永富加油站"
            //         },
            //         {
            //         "use_total": 93,
            //         "use_total_money": 1463.9000244140626,
            //         "use_station_no": "S22080416585589",
            //         "station_name": "天成加油站"
            //         }
            //     ]
            // }else{
            //     this.couponRankData = [
            //         {
            //         "use_total": 764,
            //         "use_total_money": 0.0,
            //         "use_station_no": "S22053109400564",
            //         "station_name": "新时代加油站"
            //         },
            //         {
            //         "use_total": 443,
            //         "use_total_money": 0.0,
            //         "use_station_no": "S22061310321832",
            //         "station_name": "汇丰加油站"
            //         },
            //         {
            //         "use_total": 424,
            //         "use_total_money": 0.0,
            //         "use_station_no": "S22052710383088",
            //         "station_name": "永福加油站"
            //         },
            //         {
            //         "use_total": 372,
            //         "use_total_money": 0.0,
            //         "use_station_no": "S22050515470130",
            //         "station_name": "兴珑加油站"
            //         },
            //         {
            //         "use_total": 294,
            //         "use_total_money": 0.0,
            //         "use_station_no": "S22080417382161",
            //         "station_name": "永富加油站"
            //         },
            //         {
            //         "use_total": 253,
            //         "use_total_money": 0.0,
            //         "use_station_no": "S22062009573666",
            //         "station_name": "兴华加油站"
            //         },
            //         {
            //         "use_total": 229,
            //         "use_total_money": 0.0,
            //         "use_station_no": "S22061616013992",
            //         "station_name": "望牛墩加油站"
            //         },
            //         {
            //         "use_total": 81,
            //         "use_total_money": 0.0,
            //         "use_station_no": "S22082314225228",
            //         "station_name": "匠心加油站"
            //         }
            //     ];
            // }
        },
        fetchData(){
            return new Promise((resolve, reject) => {
                getRight().then(res => {
                    this.notOilSales = res.data.notOilSales;
                    this.rank.oil_rank = res.data.oilRank;
                    this.rank.not_oil_rank = res.data.notOilRank;
                    this.luckyRank = res.data.luckyCouponRank;
                    this.carWashRank = res.data.carWashCouponRank;
                    this.eventData = res.data.event;
                    this.costomRankData = res.data.rank;
                    // 统一视图更新
                    this.freshData();
                }).catch(err => {
                    console.log('err', err)
                }).finally(() => {
                    resolve();
                })
            })
        },
        freshData(){
            // 油品费油销售排行
            this.gRankData();
            // 券核销情况
            this.gCouponRankData();
        }
    }
}
</script>
<style lang="scss" scoped>
.RightView {
    height: 100%;
    display: flex;
    flex-direction: column;
    .top {
        flex: 2;
        display: flex;

        .notOilSales {
            display: flex;
            justify-content: space-between;
            .title {
                color: #fff;
                padding: 20px 0 10px 10px;
            }
        }
    }
    .middle {
        flex: 5;
        display: flex;
    }

    .bottom {
        flex: 4;
        display: flex;
        .rank{
            height: 85%;
            display: flex;
            align-items: center;
            .tip{
                width: 100%;
                color: #fff;
                text-align: center;
                font-size: 2rem;
            }
            img {
                width: 100%;
            }
        }
    }
}
</style>
