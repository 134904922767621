import request from '@/utils/request'

export function index(query) {
  return request({
    url: '/admin/DataScreen/index',
    method: 'get',
    params: query
  })
}

export function getStationList(query) {
  return request({
    url: '/admin/DataScreen/getStationList',
    method: 'get',
    params: query
  })
}

export function getLeft(query) {
  return request({
    url: '/admin/DataScreen/getLeft',
    method: 'get',
    params: query
  })
}

export function getRight(query) {
  return request({
    url: '/admin/DataScreen/getRight',
    method: 'get',
    params: query
  })
}


export function getMiddle(query) {
  return request({
    url: '/admin/DataScreen/getMiddle',
    method: 'get',
    params: query
  })
}

export function login(query) {
    return request({
      url: '/admin/login/index',
      method: 'post',
      params: query
    })
}

export function getWeather(query) {
  return request({
    url: '/admin/DataScreen/getWeather',
    method: 'get',
    params: query
  })
}



