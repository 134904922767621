import { login } from '@/utils/api'
import Vue from 'vue'

const state = {
  enterprise_no: '',
  stations: [],
  token: '',
}

const getters = {
  enterprise_no: state => state.enterprise_no,
  token: state => state.token,
  stations: state => state.stations
}

const mutations = {
  setEnterpriseNo(state, no){ 
    console.log('no', no)
    state.enterprise_no = no;
  },
  setToken(state, token){
    state.token = token;
  },
  setStations(state, stations){
    state.stations = stations;
  }
}

const actions = {
  async login({ commit }, {username, password, no}){
    if(!no){
      Vue.prototype.$baseMessage('请输入企业编号', 'error');
      return 
    }

    localStorage.setItem('enterprise_no', no);
    let e = localStorage.getItem('enterprise_no');
    console.log('e', e)
    commit('setEnterpriseNo', no);

    login({username, password, no}).then(res => {
      let token = res.data.user_token;
      if(token) {
        localStorage.setItem('token', token);
        commit('setToken', token);
        Vue.prototype.$baseMessage('登录成功', 'success');
        console.log('登录成功')
      }
      return res
    }).catch(err => {
      console.log('err', err)
    })
  },
  setStations({ commit }, stations){
    console.log('stations', stations)
    commit('setStations', stations);
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}
