<template>
  <div class="progress-container">
    <div class="progress-bar" :class="{ 'border-radius-right': percentage >= 95 }" :style="{ width: percentage + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: 'xxyProgress',
  props: {
    percentage: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.progress-container {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  height: 7px;
}

.progress-bar {
  height: 7px;
  background: linear-gradient( 90deg, rgba(8,116,255,0) 0%, #08B1FF 100%);
  transition: width 0.3s ease;
  animation: breathing 2s infinite; /* 呼吸动效 */
}

.border-radius-right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

@keyframes breathing {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.8;
  }
}
</style>