<template>
  <div class="header">
    <div class="left">
      <div class="main">
        <login @loginSuc="handleLoginSuc"></login>
        <div>
          <i class="el-icon-loading status" v-if="fetchLoading"></i>
          <i class="el-icon-video-pause status" v-else-if="!pauseFetchFlag" @click="doSwitchPauseFetch"></i>
          <i class="el-icon-video-play status" v-else @click="doSwitchPauseFetch"></i>
          <span class="text" v-if="pauseFetchFlag">已暂停</span>
          <span class="text" v-if="fetchLoading">刷新中</span>
        </div>
      </div>

      <div class="timeBtn">
        <div class="title">时间</div>
        <div class="btn" @click="selectTime('today')" :class="{'active' : activeBtn == 1}">当天</div>
        <div class="btn" @click="selectTime('last week')" :class="{'active' : activeBtn == 7}">7天</div>
        <div class="btn" @click="selectTime('last two week')" :class="{'active' : activeBtn == 15}">15天</div>
        <div class="btn" @click="selectTime('last month')" :class="{'active' : activeBtn == 30}">30天</div>
        <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            class="timePicker"
            format="MM-dd"
            popper-class="timePickerPopper"
            @change="changeDate">
        </el-date-picker>
      </div>

      <div class="station">
        <span>站点</span>
        <el-select v-model="stationNo" placeholder="选择油站" size="mini" class="select" popper-class="selectStation">
          <el-option label="全部" value=""></el-option>
          <el-option
            v-for="item in stations"
            :key="item.station_no"
            :label="item.real_name"
            :value="item.station_no">
          </el-option>
        </el-select>
      </div>

    </div>
    <div class="center">{{ title }}</div>
    <div class="right">
      <div class="wether">
        <i v-if="weather.icon" :class="`qi-${weather.icon}`" style="font-size: 30px;"></i>
        <span class="text">{{ weather.text }} </span>
        <span v-if="weather.temperature">{{ weather.temperature }}℃</span>
      </div>
      <div class="time">
        <span>{{ newHour }}</span>
        <span>{{ newDay }}</span>
        <span>{{ newWeek | weekFormater }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import { getStationList, getWeather, index} from '@/utils/api';
import login from '@/components/login.vue';


export default {
  name: 'Head',
  components:{
    login
  },
  props:{
    fetchLoading: {
      type: Boolean,
      default: false
    },
    pauseFetchFlag:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      date: [],
      activeBtn: 1,
      stations: [],
      stationNo: '',
      newHour: '',
      newDay: '',
      newWeek: '',
      weather: {
        text: '',
        temperature: ''
      },
      title: '数据大屏',
    };
  },
  filters: {
    weekFormater(val) {
      return ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'][val];
    }
  },
  computed: {
    ...mapGetters({
      enterprise_no: 'login/enterprise_no',
    }),
  },
  watch: {
    date(val){
      this.setDate(val);
      this.$emit('fetchAll');
    },
    stationNo(val){
      this.setStation(val);
      this.$emit('fetchAll');
    }
  },
  beforeDestroy() {
    if(this.timer){
      clearInterval(this.timer);
    }
  },
  created(){
    this.init()
  },
  methods: {
    ...mapActions({
      login: 'login/login',
      setDate: 'params/setDate',
      setStation: 'params/setStation',
      setStations: 'login/setStations',
      setId: 'params/setId'
    }),
    init(){
      this.selectTime('today');
      this.timer = setInterval(this.getNow, 1000);

      var id = localStorage.getItem('boradId')
      if(!id){
        this.$baseMessage('状态异常，请重新登录', 'error')
        return 
      }
      index({id}).then((res) => {
        this.title = res.data.board.name
      }).catch(err => {
        console.log('err', err)
      })

      this.getStationList();
      this.getWether();
      setInterval(this.getWether, 1000 * 60 * 5);
    },
    getNow: function() {
      this.newHour = dayjs().format('HH:mm:ss');
      this.newDay = dayjs().format('YYYY-MM-DD');
      this.newWeek = dayjs().format('d');
    },
    handleLoginSuc(){
      this.init()
      this.$emit('fetchAll')
    },
    toLogin() {
      this.login({ username: 'root', password: '888888', no: 'c19120810062324'}).then((res) => {
      }).catch(err => {
        console.log('err', err)
      })
    },
    getStationList(){
      getStationList().then((res) => {
        this.stations = res.data;
        this.setStations(this.stations);
      }).catch(err => {
        console.log('err', err)
      })
    },
    getWether(){
      getWeather().then(res=>{
        this.weather = {
          text: res.data.now.text,
          temperature: res.data.now.temp,
          icon: res.data.now.icon
        }
        console.log('res', res)
      }).catch(err => {
        console.log('err', err)
      })
    },
    selectTime(period) {
      let startDate;
      let endDate = dayjs().endOf('day'); 

      switch (period) {
        case 'today':
          startDate = dayjs().startOf('day');
          this.activeBtn = 1;
          break;
        case 'last week':
          startDate = dayjs().subtract(7, 'day').startOf('day');
          this.activeBtn = 7;
          break;
        case 'last two week':
          startDate = dayjs().subtract(15, 'day').startOf('day');
          this.activeBtn = 15;
          break;
        case 'last month':
          startDate = dayjs().subtract(30, 'day').startOf('day');
          this.activeBtn = 30;
          break;
        default:
          startDate = dayjs().startOf('day');
          this.activeBtn = 1;
          break;
      }
      this.date = [startDate.format('YYYY-MM-DD HH:mm:ss'), endDate.format('YYYY-MM-DD HH:mm:ss')];
    },
    changeDate(){
      this.activeBtn = -1; 
    },
    doSwitchPauseFetch(){
      this.$emit('pauseFetch')
    },
  }
}
</script>

<style lang="scss">
.header {
  height: 8%;
  display: flex;
  align-items: center;
  background-image: url('~@/assets/bg_head.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: #040965;

  .left {
    width: 35%;
    align-items: center;
    color: #fff;
    display: flex;
    font-size: 0.9rem;


    .main{
      display: flex;
      min-width: 15%;

      .status{
        width: 1.3rem;
        margin-left: 8px;
        font-size: 1rem;
        cursor: pointer;
      }

      .status:hover{
        color: #2d6bdf;
        font-size: 1.1rem;
      }

      .text{
        font-size: 0.8rem;
      }
    }
    
    .timeBtn{
      display: flex;
      max-width: 60%;

      .title{
        display: flex;
        align-items: center;
        padding: 5px 10px;
      }

      .btn{
        display: flex;
        align-items: center;
        padding: 5px;
        background: linear-gradient( 180deg, #0A1028 0%, #1E41A7 100%);
        cursor: pointer;
        &:hover{
          background: linear-gradient( 135deg, #5377FA 0%, #1E41A7 100%);
        }
        &.active{
          background: linear-gradient( 135deg, #5377FA 0%, #1E41A7 100%);
        }
      }

      .timePicker{
        width: 40%;
        background: linear-gradient( 180deg, #183394 0%, #1E41A7 100%);
        border: none;
        border-radius: 0;
        margin: auto 0 ;
        .el-range-input{
          color: #fff;
          background: none;
        }
        .el-range-separator{
          color: #fff;
        }
      }
    }

    .station{
      padding-left: 10px;
      .select{
        width: 70%;
        margin-left: 10px;
        .el-input__inner{
          color: #fff;
          background: linear-gradient( 180deg, rgba(24, 51, 148, 0.6) 0%, rgba(30, 65, 167,0.6) 100%);
          border: none;
        }
      }
    }
    
  }

  .center{
    width: 30%;
    color: #fff;
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
  }

  .right{
    color: #fff;
    width: 35%;
    display: flex;
    .wether{
      width: 50%;
      text-align: center;
      .text{
        margin-left: 20px;
      }
      span{
        font-size: 1rem;
      }
    }
    .time{
      width: 50%;
      padding-right: 1rem;
      display: flex;
      justify-content: space-between;
      height: 2rem;
      line-height: 2rem;
    }
  }
}

</style>