<template>
  <span 
    :class="{'button': true, 'active': isActive}" 
    @click="handleClick"
  >
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'xxyButton',
  props: {
    value: [String, Number, Boolean], // v-model 绑定的值
    active: [String, Number, Boolean] // 传入的激活状态值
  },
  computed: {
    isActive() {
      return this.value === this.active;
    }
  },
  methods: {
    handleClick() {
      this.$emit('input', this.active); // 更新 v-model 绑定的值
      this.$emit('click');
    }
  }
}
</script>

<style lang="scss" scoped>

</style>