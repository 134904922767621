<template>
  <div class="container">
    <div class="boardRow" >
      <div class="header">
          <span style="width: 20%;">#</span>
          <span style="width: 60%;">加油站</span>
          <!-- <span style="width: 30%;">已发放</span> -->
          <span>已使用</span>
        </div>
    </div>
    <div class="scroll" ref="scroll">
      <div class="boardRow" ref="scrollRow" v-for="(item,index) in data">
          <div>
            <span style="width: 20%;">NO.{{ index+1 }}</span>
            <span style="width: 60%;">{{ item.station_name }}</span>
            <!-- <span style="width: 30%;">已发放</span> -->
            <span>{{ item.use_total }}</span>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'xxyCouponRankingBoard',
  props: {
    data: {
      type: Array,
      default: []
    },
    pause: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      timer: null
    }
  },
  filters: {
    removeStationName(value) {
      return value.replace('加油站', '');
    }
  },
  computed: {
   
  },
  created() {
    this.begin()
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    begin(){
      this.timer = setInterval(this.rollStart, 3000);
    },
    rollStart() {
      if(this.pause) return
      const scroll = this.$refs.scroll;
      var row = this.$refs.scrollRow;

      if(!row || !scroll){
        return this.changeView()
      }
      row = row[0]
      const step = 1; // 设置每帧滚动的步长，可以调整平滑度

      const smoothScroll = () => {
        if (scroll.scrollTop + scroll.clientHeight < scroll.scrollHeight) {
          scroll.scrollTop += step;
          // 如果还没有达到下一个元素的位置，继续请求下一帧
          if (scroll.scrollTop % row.clientHeight !== 0) {
            requestAnimationFrame(smoothScroll);
          }
        }
      };
      if(scroll.scrollTop + scroll.clientHeight >= scroll.scrollHeight){
        this.changeView()
      }else{
        smoothScroll();
      } 
    },
    changeView(){
      clearInterval(this.timer);
      setTimeout(() => {
        this.$refs.scroll.scrollTop = 0
        this.$emit('changeView');
      }, 3000);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .boardRow{
    color: #fff;
    padding: 5px;
    line-height: 1.1rem;
    font-size: 1rem;

    .header{
      background: #02055E;
    }
    span{
      display: inline-block;
    }
  }
}

.scroll{
    height: 27vh;
    overflow-y: auto;
  }

</style>