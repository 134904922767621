<template>
  <div class="container">
    <div class="boardRow">
      <div class="rank"></div>
      <div class="detail title">
        <span class="station">油站</span>
        <span class="money"><slot></slot>金额</span>
        <span>订单数</span>
      </div>
    </div>
    <div class="scroll" ref="scroll">
      <div class="boardRow" ref="scrollRow" v-for="(item,index) in data">
        <div class="rank data">
          {{ index+1 }}
        </div>
        <div class="detail data">
          <span class="station">{{ item.station_name | removeStationName}}</span>
          <span class="money">￥{{ item.pay_money }}</span>
          <span>{{ item.order_count }}</span>
          <!-- <div class="progress">
            <xxyProgress :percentage="item.proportion"></xxyProgress>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xxyProgress from '@/components/xxy-progress.vue'
export default {
  name: 'xxySalesDetailBoard',
  components: {
    xxyProgress
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    }
  },
  filters: {
    removeStationName(value) {
      return value.replace('加油站', '');
    }
  },
  computed: {
   
  },
  created() {
    setInterval(this.rollStart, 3000);
  },
  methods:{
    rollStart() {
      const scroll = this.$refs.scroll;
      var row = this.$refs.scrollRow;

      if(!row || !scroll){
        return
      }
      row = row[0]
      const step = 1; // 设置每帧滚动的步长，可以调整平滑度
      const smoothScroll = () => {
        if (scroll.scrollTop + scroll.clientHeight < scroll.scrollHeight) {
          scroll.scrollTop += step;
          // 如果还没有达到下一个元素的位置，继续请求下一帧
          if (scroll.scrollTop % row.clientHeight !== 0) {
            requestAnimationFrame(smoothScroll);
          }
        }
      };
      
      if(scroll.scrollTop + scroll.clientHeight >= scroll.scrollHeight){
        scroll.scrollTop = 0;
      }else{
        smoothScroll();
      } 
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  .boardRow{
    color: #fff;
    padding: 3px;
    display: flex;
    // 不能用space-between，因为标题元素
    align-items: center;
    line-height: 1.5rem;

    .rank{
      height: 1.5rem;
      width: 1.5rem;
      background: linear-gradient( 155deg, #050C97 0%, #02055E 100%);
      border: 1px solid #0A35F9;
      font-size: 1rem;
      color: #fff;
      text-align: center;
    }

    .detail{
      margin-left: 0.5rem;
      display: flex;
      background: #02055eb2;
      padding-right: 5px;
      font-size: 1rem;
      flex: 1;

      .station{
        width: 35%;
      }

      .money{
        width: 45%;
      }

      .progress{
        flex: 1;
        display: flex;
        align-items: center;
      }
    }

    .dataBoard{
      flex: 4;
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
      font-size: 1rem;
      background: #02055E;
    }

    .title{
      font-size: 1rem;
    }
  }
}

   
.scroll{
  height: 28vh;
  overflow-y: scroll;
}

</style>  