<template>
<div >
  <div v-for="(char, index) in splitNum" style=" display: inline-block;">
    <div class="box-item" v-if="!isNaN(char)">
      <span ref="numberItem" :style="{transform: `translate(-50%, -${Number(char) * 10}%)`}">0123456789</span>
    </div>
    <span v-else class="point"></span>
  </div>
</div>

</template>

<script>
export default {
  name: 'eNumber',
  props: {
    num: {
      type: [String, Number],
      default: 0
    },
    cstyle: {
      type: Object,
    }
  },
  computed: {
    splitNum() {
      const numStr = typeof this.num === 'number' ? this.num.toString() : this.num;
      return numStr.split('');
    }
  },
  watch: {
    num() {
    }
  },
  created(){
    //setInterval(this.getRandNum, 3000)
  },
  methods:{
    getRandNum(){
      this.num = Math.floor(Math.random() * 10000000)
    }
  }
}
</script>

<style lang="scss" scoped>

.box-item {
  display: inline-block;
  width: 1.3rem;
  height: 2.5rem;
  background: url('~@/assets/num-bg.png') no-repeat center center;
  color: #fff;
  background-size: 100% 100%;
  font-size: 1.5rem;
  line-height: 2.5rem;
  text-align: center;
  position: relative;
  writing-mode: vertical-lr;
  text-orientation: upright;
  overflow: hidden;
  margin: 5px 1px;
}

.box-item span {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 10px;
  transition: transform 1.5s ease-in-out;
}

.point{
  display: inline-block; 
  width: 0.3rem;
  height: 0.3rem; 
  background-color: #fff;
  border-radius: 2px;
  margin: 10px 3px;
}

</style>