import Vue from 'vue'
import dayjs from 'dayjs'

const state = {
  start_time: '',
  end_time: '',
  station_no: '',
  id: ''
}

const getters = {
  start_time: (state) => {
    if(!state.start_time){
      return dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    return state.start_time;
  },
  end_time: (state) => {
    if(!state.end_time){
      return dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss');
    }
    return state.end_time;
  },
  station_no: (state) => {
    return state.station_no;
  },
  id: (state) => {
    return state.id;
  }
}

const mutations = {
  setDate(state, {start, end}){ 
      state.start_time = start;
      state.end_time = end;
  },
  setStartTime(state, start_time){
    console.log('start_time', start_time)
    state.start_time = start_time;
  },
  setEndTime(state, end_time){
    state.end_time = end_time;
  },
  setStation(state, station_no){
    state.station_no = station_no;
  },
  setId(state, id){
    console.log('setId', id)
    state.id = id;
  }
}

const actions = {
  setDate({commit}, dateRange){ 
    if(dateRange && dateRange.length == 2){
      commit('setDate', {start: dateRange[0], end: dateRange[1]});
    }
  },
  setStation({commit}, station_no){
    commit('setStation', station_no);
  },
  add30Seconds({commit, getters}){
    // 开始时间提前30s
    commit('setStartTime', dayjs(getters['start_time']).subtract(600, 'second').format('YYYY-MM-DD HH:mm:ss'));
  },
  setId({commit}, id){
    commit('setId', id);
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}
