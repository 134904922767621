import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import './global.scss'
import {Message} from 'element-ui'
import 'qweather-icons/font/qweather-icons.css'

let messageDuration = 3000;

/* 全局Message */
Vue.prototype.$baseMessage = (message, type, dangerouslyUseHTMLString) => {
  Message({
    showClose: true,
    message: message,
    type: type,
    dangerouslyUseHTMLString,
    duration: messageDuration,
  })
}

Vue.use(dataV);
Vue.use(ElementUI);

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
